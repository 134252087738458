.photoPaneWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
  gap: 50px;
  padding: 150px 50px;
}

@media screen and (max-width: 990px) {
  .photoPaneWrapper {
    grid-template-columns: 1fr;
    max-width: 550px;
    margin: auto;
    padding: 100px 50px;
  }
}

@media screen and (max-width: 750px) {
  .photoPaneWrapper {
    padding: 80px 35px;
  }
}

@media screen and (max-width: 580px) {
  .photoPaneWrapper {
    padding: 50px 15px;
  }
}

.photoPaneFigure {
  display: block;
  width: 100%;
  height: auto;
  padding: 7%;
  margin: 0;
  aspect-ratio: 4 / 5;
  position: relative;
}

.photoPaneFigure img:not(.photoPanePhoto) {
  position: absolute;
  max-width: 160px;
  width: 40%;
  height: auto;
  z-index: 10;
}

@media screen and (max-width: 990px) {
  .photoPaneWrapper .photoPaneFigure:nth-of-type(1),
  .photoPaneWrapper .photoPaneFigure:nth-of-type(3) {
    display: none;
  }

  .photoPaneFigure img:not(.photoPanePhoto) {
    max-width: 140px;
  }
}

@media screen and (max-width: 750px) {
  .photoPaneFigure {
    padding: 20px;
  }

  .photoPaneFigure img:not(.photoPanePhoto) {
    width: 70px;
  }
}

.photoPaneFigure img:not(.photoPanePhoto):nth-of-type(1) {
  left: 0;
  top: 0;
}

.photoPaneFigure img:not(.photoPanePhoto):nth-of-type(2) {
  top: 0;
  right: 0;
  transform: rotate(90deg);
}

.photoPaneFigure img:not(.photoPanePhoto):nth-of-type(3) {
  right: 0;
  bottom: 0;
  transform: rotate(180deg);
}

.photoPaneFigure img:not(.photoPanePhoto):nth-of-type(4) {
  bottom: 0;
  left: 0;
  transform: rotate(-90deg);
}

.photoPaneFigure .photoPanePhoto {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: grayscale(100%) sepia(40%);
  will-change: filter;
  transition: filter ease-in-out 750ms;
}

@media screen and (pointer: fine) {
  .photoPaneFigure:hover .photoPanePhoto {
    filter: grayscale(0%) sepia(0%);
    cursor: pointer;
  }
}
