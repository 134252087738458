.scheduleWrapper {
  display: block;
  padding-top: 50px;
  max-width: 750px;
  margin: auto;
}

.scheduleWrapper ul {
  display: flex;
  flex-flow: column nowrap;
  gap: 20px;
  list-style: none;
  padding: 0;
  margin: 0;
}

.scheduleWrapper ul li {
  display: grid;
  grid-template-columns: 45px 1fr auto;
  grid-template-areas: "time title tagline";
  gap: 15px;
  color: var(--brown);
}

.scheduleWrapper ul li .time {
  grid-area: time;
  font-style: italic;
  color: var(--brown-transparent);
}

.scheduleWrapper ul li .title {
  grid-area: title;
  font-weight: bold;
}

.scheduleWrapper ul li .tagline {
  grid-area: tagline;
}

.scheduleWrapper ul li .tagline b {
  color: var(--brown-transparent);
}

@media screen and (max-width: 650px) {
  .scheduleWrapper ul li {
    grid-template-columns: 45px 1fr;
    grid-template-rows: auto auto;
    grid-template-areas: "time title" ". tagline";
  }
}

.scheduleNote {
  padding-top: 20px;
  padding-left: 60px;
}

.scheduleNote p {
  font-size: 14px;
}
