.displayHeading {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
  padding: 15px 0;
  font-size: 20px;
}

@media screen and (max-width: 930px) {
  .displayHeading {
    gap: 30px;
  }
}

.displayHeading img {
  width: 120px;
  height: auto;
  display: block;
}

@media screen and (max-width: 1090px) {
  .displayHeading img {
    width: 90px;
    margin: 0;
  }
}

@media screen and (max-width: 930px) {
  .displayHeading img {
    width: 50px;
    margin: 0;
  }
}

@media screen and (max-width: 580px) {
  .displayHeading img {
    display: none;
  }
}

.displayHeading img:nth-of-type(2) {
  transform: rotate(180deg);
}

.scheduleList {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  gap: 7px;
  width: 100%;
  height: auto;
  text-align: center;
  padding: 30px 0;
  margin: 0;
  list-style: none;
}

.scheduleList p {
  font-size: 14px;
}

.minifyH4 {
  font-size: 18px;
  line-height: 1.5;
  margin: 0 auto;
  width: 90%;
  max-width: 750px;
}

.minifyH4.joiner {
  position: relative;
}

.minifyH4.joiner img {
  width: 70px;
  height: auto;
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

@media screen and (max-width: 1090px) {
  .minifyH4.joiner img {
    width: 50px;
    margin: 0;
  }
}

@media screen and (max-width: 930px) {
  .minifyH4.joiner img {
    width: 40px;
    margin: 0;
  }
}

@media screen and (max-width: 580px) {
  .minifyH4.joiner img {
    display: none;
  }
}

.minifyH4.joiner img:nth-of-type(2) {
  transform: rotate(180deg) translateY(50%);
  left: auto;
  right: 0;
}

@media screen and (max-width: 600px) {
  .minifyH4 {
    max-width: 450px;
  }
}

.ancestryColumns {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Badhorse;
  color: var(--brown);
  margin: 20px auto 30px auto;
  font-size: 18px;
}

.ancestryColumns .ancestryColumn span {
  opacity: 0.65;
}

@media screen and (max-width: 930px) {
  .ancestryColumns {
    font-size: 16px;
    margin: 10px auto 20px auto;
  }
}

@media screen and (max-width: 600px) {
  .ancestryColumns {
    font-size: 12px;
    width: 80%;
  }
}

.lodgingList {
  list-style: none;
  padding: 15px 0;
  margin: 0;
  display: flex;
  flex-flow: column nowrap;
  gap: 5px;
}

.lodgingList li {
  padding-left: 40px;
  position: relative;
}

.lodgingList li a {
  text-decoration: underline !important;
}

.lodgingList li::before {
  content: "";
  display: block;
  width: 5px;
  height: 5px;
  background-color: var(--brown);
  position: absolute;
  left: 17.5px;
  top: calc((35.2px / 2) - 2.5px);
}
