.sectionWrapper {
  display: block;
  width: 90%;
  margin: auto;
  padding-top: 57px;
}

.sectionHeader {
  display: block;
  text-align: center;
  padding-bottom: 40px;
}

.sectionContent {
  width: 100%;
  max-width: 950px;
  margin: auto;
  padding-bottom: 97px;
  text-align: justify;
}

@media screen and (max-width: 930px) {
  .sectionWrapper {
    padding-top: 27px;
  }

  .sectionContent {
    padding-bottom: 47px;
  }
}

@media screen and (max-width: 580px) {
  .sectionWrapper {
    padding-top: 47px;
  }
}
