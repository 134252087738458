:root {
  --background: #eee7db;

  --text: #374a63;
  --brown: #725e48;
  --brown-transparent: hsla(31, 23%, 36%, 0.5);

  /* Wedding colours */
  --blue-gray: #abc4dd;
  --blue-gray-dark: #87aacf;

  --naples-yellow: #f8dd87;
  --naples-yellow-dark: #f5d057;

  --persian-orange: #f0a875;
  --persian-orange-dark: #eb8560;

  --atomic-tangerine: #f1a88e;
  --atomic-tangerine-dark: #e66233;

  --olivine: #a2c496;
  --olivine-dark: #85b276;

  --lilac: #e3d2f9;
  --lilac-dark: #c7a6f2;
}
