.containerWrapper {
  display: block;
  width: 100%;
  height: auto;
  padding: 120px 50px;
  position: relative;
}

@media screen and (max-width: 750px) {
  .containerWrapper {
    padding: 80px 35px;
  }
}

@media screen and (max-width: 580px) {
  .containerWrapper {
    padding: 50px 15px;
  }
}

.containerWrapper.centerContent {
  display: flex;
  align-items: center;
  justify-content: center;
}

.containerWrapper figure {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0;
  margin: 0;
  pointer-events: none;
}

.containerWrapper figure img {
  position: absolute;
  display: block;
  width: 200px;
  height: auto;
}

.containerWrapper figure img:nth-child(1) {
  top: 50px;
  left: 50px;
}

@media screen and (max-width: 750px) {
  .containerWrapper figure img:nth-child(1) {
    top: 35px;
    left: 35px;
  }
}

@media screen and (max-width: 580px) {
  .containerWrapper figure img:nth-child(1) {
    top: 15px;
    left: 15px;
  }
}

.containerWrapper figure img:nth-child(2) {
  top: 50px;
  right: 50px;
  transform: rotate(90deg);
}

@media screen and (max-width: 750px) {
  .containerWrapper figure img:nth-child(2) {
    top: 35px;
    right: 35px;
  }
}

@media screen and (max-width: 580px) {
  .containerWrapper figure img:nth-child(2) {
    top: 15px;
    right: 15px;
  }
}

.containerWrapper figure img:nth-child(3) {
  bottom: 50px;
  left: 50px;
  transform: rotate(270deg);
}

@media screen and (max-width: 750px) {
  .containerWrapper figure img:nth-child(3) {
    bottom: 35px;
    left: 35px;
  }
}

@media screen and (max-width: 580px) {
  .containerWrapper figure img:nth-child(3) {
    bottom: 15px;
    left: 15px;
  }
}

.containerWrapper figure img:nth-child(4) {
  bottom: 50px;
  right: 50px;
  transform: rotate(180deg);
}

@media screen and (max-width: 750px) {
  .containerWrapper figure img:nth-child(4) {
    bottom: 35px;
    right: 35px;
  }
}

@media screen and (max-width: 580px) {
  .containerWrapper figure img:nth-child(4) {
    bottom: 15px;
    right: 15px;
  }
}

@media screen and (max-width: 1090px) {
  .containerWrapper figure img {
    width: 150px;
  }
}

@media screen and (max-width: 750px) {
  .containerWrapper figure img {
    width: 70px;
  }
}

.containerContent {
  display: block;
  width: 100%;
  height: auto;
  position: relative;
  z-index: 2;
}

.title {
  font-family: Rosella;
  color: var(--text);
  font-size: 28px;
  position: absolute;
  letter-spacing: 15px;
  line-height: 50px;
  left: 50%;
  transform: translateX(-50%);
}

@media screen and (max-width: 1290px) {
  .title {
    font-size: 20px;
  }
}

@media screen and (max-width: 1090px) {
  .title {
    font-size: 18px;
  }
}

@media screen and (max-width: 950px) {
  .title {
    font-size: 14px;
    letter-spacing: 5px;
  }
}

@media screen and (max-width: 750px) {
  .title {
    letter-spacing: unset;
    text-align: center;
  }
}

.title.topTitle {
  top: 40px;
}

@media screen and (max-width: 750px) {
  .title.topTitle {
    top: 18px;
  }
}

@media screen and (max-width: 580px) {
  .title.topTitle {
    top: -4px;
  }
}

.title.bottomTitle {
  bottom: 40px;
}

@media screen and (max-width: 750px) {
  .title.bottomTitle {
    bottom: 18px;
  }
}

@media screen and (max-width: 580px) {
  .title.bottomTitle {
    bottom: -4px;
  }
}

.monogramWrapper {
  position: absolute;
  z-index: 1;
  width: 90%;
  height: 90%;
  max-width: 500px;
  max-height: 500px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.monogramWrapper img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
  opacity: 0.05;
}
