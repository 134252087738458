.bannerWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 0;
}

.bannerInner {
  display: block;
  position: relative;
  line-height: 80px;
  height: 80px;
  padding: 0 110px;
  font-size: 30px;
  font-family: Hertical;
  color: var(--brown-transparent);
}

@media screen and (max-width: 930px) {
  .bannerInner {
    line-height: 80px;
    height: 80px;
    padding: 0 110px;
  }
}

@media screen and (max-width: 750px) {
  .bannerInner {
    font-size: 20px;
    line-height: 60px;
    height: 60px;
    padding: 0 80px;
  }
}

@media screen and (max-width: 580px) {
  .bannerInner {
    font-size: 18px;
    line-height: 40px;
    height: 40px;
    padding: 0 50px;
    white-space: nowrap;
  }
}

.bannerInner img {
  position: absolute;
  height: 100%;
  width: auto;
  display: block;
  top: 0;
  pointer-events: none;
}

.bannerInner img:nth-of-type(1) {
  left: 0;
}

.bannerInner img:nth-of-type(2) {
  right: 0;
}
