.landingPageWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 750px) {
  .landingPageWrapper {
    padding: 35px 0;
  }
}

.landingPageWrapper .landingPageInner {
  display: block;
  text-align: center;
}

.landingPageWrapper .landingPageInner .titleWrapper {
  padding-bottom: 50px;
}

.landingPageWrapper .landingPageInner .titleWrapper p {
  font-size: 90%;
  line-height: 1.4;
  max-width: 750px;
  margin: 0 auto;
}

.displayHeading {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
  padding: 2px 0 10px 0;
}

@media screen and (max-width: 930px) {
  .displayHeading {
    gap: 30px;
  }
}

.displayHeading img {
  width: 120px;
  height: auto;
  display: block;
}

@media screen and (max-width: 1090px) {
  .displayHeading img {
    width: 90px;
    margin: 0;
  }
}

@media screen and (max-width: 930px) {
  .displayHeading img {
    width: 50px;
    margin: 0;
  }
}

@media screen and (max-width: 580px) {
  .displayHeading img {
    display: none;
  }
}

.displayHeading img:nth-of-type(2) {
  transform: rotate(180deg);
}

.buttonWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  padding-top: 30px;
}

@media screen and (max-width: 750px) {
  .buttonWrapper {
    flex-flow: column nowrap;
  }
}

.welcomeWrapper {
  display: block;
  width: 100%;
  margin: 0 auto;
}

@media screen and (max-width: 930px) {
  .welcomeWrapper {
    width: 90%;
  }
}

.paddedParagraph {
  padding-bottom: 15px;
}

.largerParagraph {
  font-size: 18px !important;
}