*,
*::before,
*::after {
  box-sizing: border-box;
  outline: none;
  min-width: 0;
}

:root {
  font-family: "Martel", serif;
}

@font-face {
  font-family: Badhorse;
  src: url(../src/assets/badhorse.otf);
  font-weight: 400;
}

@font-face {
  font-family: Hertical;
  src: url(../src/assets/hertical.ttf);
  font-weight: bold;
}

@font-face {
  font-family: Rosella;
  src: url(../src/assets/rosella.ttf);
  font-weight: bold;
}

body {
  padding: 0;
  margin: 0;
  background-color: var(--background);
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  display: block;
  margin: 0;
}

h1.display1,
h1.display2 {
  font-family: Badhorse;
  font-feature-settings: "ss01" 1;
  font-size: 120px;
  color: var(--text);
  margin: 0;
  font-weight: lighter;
}

h1.display2 {
  font-size: 60px;
}

@media screen and (max-width: 1090px) {
  h1.display1 {
    font-size: 90px;
  }
}

@media screen and (max-width: 930px) {
  h1.display1 {
    font-size: 50px;
  }
}

@media screen and (max-width: 1090px) {
  h1.display2 {
    font-size: 60px;
  }
}

@media screen and (max-width: 930px) {
  h1.display2 {
    font-size: 40px;
  }
}

h1 {
  font-family: Badhorse;
  font-size: 65px;
  color: var(--text);
  margin: 0;
  font-feature-settings: "ss02" 1;
}

@media screen and (max-width: 1090px) {
  h1 {
    font-size: 55px;
  }
}

@media screen and (max-width: 930px) {
  h1 {
    font-size: 40px;
  }
}

h2 {
  font-family: "Grenze Gotisch", serif;
  color: var(--text);
  margin: 0;
  font-size: 65px;
  font-weight: 200;
  letter-spacing: 5px;
}

@media screen and (max-width: 1090px) {
  h2 {
    font-size: 45px;
  }
}

@media screen and (max-width: 930px) {
  h2 {
    font-size: 35px;
  }
}

h3 {
  font-family: Rosella;
  font-size: 50px;
  color: var(--text);
}

@media screen and (max-width: 1090px) {
  h3 {
    font-size: 40px;
  }
}

@media screen and (max-width: 930px) {
  h3 {
    font-size: 30px;
  }
}

h4 {
  font-family: "Grenze Gotisch", serif;
  color: var(--text);
  margin: 0;
  font-size: 35px;
  font-weight: 200;
  letter-spacing: 5px;
}

@media screen and (max-width: 1090px) {
  h4 {
    font-size: 28px;
  }
}

@media screen and (max-width: 930px) {
  h4 {
    font-size: 20px;
  }
}

h5 {
  font-family: "Grenze Gotisch", serif;
  color: var(--text);
  margin: 0;
  font-size: 30px;
  font-weight: 200;
  letter-spacing: 5px;
}

@media screen and (max-width: 1090px) {
  h5 {
    font-size: 25px;
  }
}

@media screen and (max-width: 930px) {
  h5 {
    font-size: 18px;
  }
}

h6 {
  font-family: "Grenze Gotisch", serif;
  color: var(--brown);
  margin: 0;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 5px;
}

p {
  font-size: 18px;
  color: var(--brown);
  line-height: 2.2;
  letter-spacing: 2px;
  font-weight: 100;
}

@media screen and (max-width: 1090px) {
  p {
    font-size: 16px;
  }
}

address {
  display: block;
  padding: 30px 0;
}

address span {
  display: block;
}

.button {
  all: unset;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 300px;
  height: 45px;
  border: solid 4px var(--brown);
  font-family: Hertical;
  font-size: 20px;
  color: var(--brown);
  cursor: pointer;
  will-change: background, color;
  transition-property: background colour;
  transition: cubic-bezier(0.455, 0.03, 0.515, 0.955) 300ms;
}

@media screen and (max-width: 930px) {
  .button {
    min-width: 200px;
    height: 40px;
  }
}

.button:hover {
  background: var(--brown);
  color: var(--background);
}

.displayOrnament {
  display: block;
  width: 230px;
  height: auto;
  margin: 0 auto;
}

@media screen and (max-width: 930px) {
  .displayOrnament {
    width: 180px;
  }
}

.displayOrnament.displayOrnamentBottom {
  transform: rotate(180deg);
}

.comforter {
  padding: 50px 0;
}

.venueBlock {
  display: block;
  padding: 34px 0;
  text-decoration: none;
}

.venueBlock h1 {
  padding-bottom: 10px;
}

.venueBlock span {
  color: var(--brown);
  line-height: 1.5;
  text-decoration: underline;
}

.messageBlock,
.rsvpBlock {
  display: block;
  width: 90%;
  max-width: 950px;
  margin: auto;
  padding: 30px 0;
}

.messageBlock p,
.rsvpBlock p {
  padding-bottom: 50px;
}

.rsvpBlock p:last-child {
  padding-bottom: 0;
}

.greetingWrapper {
  width: 90%;
  margin: 0 auto;
  display: flex;
  padding-bottom: 97px;
}

.greetingWrapper h1.display2 {
  max-width: 950px;
  margin: 0 auto;
  text-align: center;
}

.greetingWrapper img {
  display: block;
  width: 60px;
  height: auto;
}

.greetingWrapper img:nth-child(1) {
  transform: rotate(180deg);
}

@media screen and (max-width: 600px) {
  .greetingWrapper img {
    display: none;
  }
}

p strong {
  font-weight: 700 !important;
}

.bgImage {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  aspect-ratio: 1 / 1;
  width: 90%;
  max-width: 650px;
  border: solid 1px var(--text);
  border-radius: 400px;
  z-index: 1;
  opacity: 0.15;
  overflow: hidden;
}

.bgImage img {
  object-fit: cover;
  display: block;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 750px) {
  .bgImage {
    display: none;
  }
}

.clearLink {
  text-decoration: none;
  color: inherit;
}
