.underline {
  display: block;
  width: 100%;
  height: auto;
}

.underline img {
  display: block;
  width: 100%;
  margin: 0 auto;
}
